<template>
  <div id="video-details" class="pa-4">
    <v-container>
      <!-- <div class="d-flex justify-space-between mb-5"> -->
      <v-btn text small class="pl-0" @click="$router.go(-1)"
        ><v-icon left>mdi-arrow-left</v-icon> Channel videos</v-btn
      >
     
      <div class="left_middle_section">
         <h2 class="video_top_title">Video details</h2>
      <v-row>
        <v-col cols="8">
          <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
            <form @submit.prevent="handleSubmit(submit)" @reset.prevent="reset">
              <ValidationProvider
                v-slot="{ errors }"
                name="Title"
                rules="required|min:3"
              >
                <v-text-field
                  :loading="inputLoading"
                  v-model="formData.title"
                  :error-messages="errors"
                  label="Title (required)"
                  class="mb-3"
                  outlined
                  dense
                  counter="100"
                  max-length="100"
                ></v-text-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="Description"
                rules="required|min:3"
              >
                <v-textarea
                  :loading="inputLoading"
                  outlined
                  dense
                  auto-grow
                  :error-messages="errors"
                  label="Description"
                  placeholder="Tell viewers about your video"
                  rows="5"
                  counter="5000"
                  max-length="5000"
                  v-model="formData.description"
                  row-height="20"
                ></v-textarea>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="Visibilty"
                rules="required|oneOf:private,public"
              >
                <v-select
                  :loading="inputLoading"
                  :items="visibility"
                  :error-messages="errors"
                 outlined
            dense
                  label="Visibilty"
                  :value="formData.visibility"
                  v-model="formData.visibility"
                ></v-select>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="Cateogry"
                rules="required|min:3"
              >
                <v-select
                  :loading="categoryLoading"
                  :items="categoriesTitles"
                  :error-messages="errors"
                 outlined
            dense
                  label="Categories"
                  v-model="formData.category"
                ></v-select>
              </ValidationProvider>

              <!-- meta details -->

              <ValidationProvider
                v-slot="{ errors }"
                name="Meta Title"
                rules="required|min:3"
              >
                <v-text-field
                  :loading="inputLoading"
                  v-model="formData.meta_title"
                  :error-messages="errors"
                  label="Meta Title"
                  class="mb-3"
                  outlined
                  dense
                  counter="100"
                  max-length="100"
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Meta Keyword"
                rules="required|min:3"
              >
                <v-text-field
                  :loading="inputLoading"
                  v-model="formData.meta_keyword"
                  :error-messages="errors"
                  label="Meta Keyword"
                  class="mb-3"
                  outlined
                  dense
                  counter="100"
                  max-length="100"
                ></v-text-field>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Meta Description"
                rules="required|min:3"
              >
                <v-textarea
                  :loading="inputLoading"
                  outlined
                  dense
                  auto-grow
                  :error-messages="errors"
                  label="Meta Description"
                  placeholder="Tell viewers about your video"
                  rows="5"
                  counter="5000"
                  max-length="5000"
                  v-model="formData.meta_description"
                  row-height="20"
                ></v-textarea>
              </ValidationProvider>

              <div class="mt-6 d-flex justify-space-between">
                <v-btn
                  :loading="submitLoading"
                  type="submit"
                  class="primary"
                  depressed
                  >Submit</v-btn
                >
              </div>
            </form>
          </ValidationObserver>
        </v-col>
        <v-col cols="4" class="text-center position_rel">
          
          <!-- <v-icon>mdi-image-plus</v-icon> -->
         <!-- <div class="photo_add_"><v-btn text @click="toggleShow">Change the Photo</v-btn></div> -->
         <!-- {{imgDataUrl}} -->
          <my-upload
            field="thumbnail"
            @crop-success="cropSuccess"
            method="PUT"
            v-model="show"
            :width="1280"
            :height="720"
            :url="url"
            :headers="headers"
            img-format="jpg"
            langType="en"
          ></my-upload>
          <v-responsive style="max-width: 100%" class="video_no_images">
            <div @click="toggleShow" style="cursor:pointer" v-if="!imgDataUrl" class="px-12 " id="image-placeholder">
              <v-icon >mdi-image-plus</v-icon>
            </div>
            <v-img v-else height="300" :src="imgDataUrl"></v-img>
          </v-responsive>
          
          
          <v-btn v-if="imgDataUrl != ''" text @click="toggleShow" depressed style="background:#ff8100; color:white">Change the Photo</v-btn>
           
        </v-col>
        
        <v-col>
         
        </v-col>
      </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import myUpload from 'vue-image-crop-upload'
import VideoService from '@/services/VideoService'
import CategoryService from '@/services/CategoryService'

export default {
  name: 'Details',
  data() {
    return {
      // dialog: this.openDialog ? this.openDialog : false,
      inputLoading: false,
      submitLoading: false,
      categoryLoading: false,
      value: 0,
      show: false,
      rules: [
        (value) =>
          !value ||
          value.size > 0 ||
          'Video size should be 2 MB or more!'
      ],
      categoriesTitles: [],
      categories: [],
      visibility: ['public', 'private'],
      formData: {
        title: '',
        description: '',
        category: '',
        visibility: '',
        meta_title: '',
        meta_keyword: '',
        meta_description: ''
      },

      imgDataUrl: '',
      url: '',
      headers: { Authorization: `Bearer ${this.$store.getters.getToken}` }
    }
  },
  methods: {
    async getVideo() {
      this.inputLoading = true
      let video = await VideoService.getById(this.$route.params.id)
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.inputLoading = false
        })
      console.log(video)
      if (!video) return
      video = video.data.data
      this.url = `${video._id}`

      this.formData.title = video.title
      this.formData.description = video.description
      this.formData.meta_title = video.meta_title
      this.formData.meta_description = video.meta_description
      this.formData.meta_keyword = video.meta_keyword
      this.formData.visibility = video.status == 'draft' ? '' : video.status
      this.formData.category = video.categoryId.title
      this.imgDataUrl = `${video.thumbnailUrl}`
    },
    async submit() {
      console.log("formData", this.formData)
      // if (this.$route.name === 'Dashboard')
      this.submitLoading = true
      this.formData.category = this.categories.find(
        (category) => category.title === this.formData.category
      )._id
      const video = await VideoService.updateVideo(this.$route.params.id, {
        title: this.formData.title,
        description: this.formData.description,
        meta_title: this.formData.meta_title,
        meta_keyword: this.formData.meta_keyword,
        meta_description: this.formData.meta_description,
        categoryId: this.formData.category,
        is_approved: 0,
        status: this.formData.visibility.toLowerCase()
      })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {

            this.submitLoading = false;
          this.uploaded = false;
          this.$toasted.show("Your Video has been uploaded succesfully", {
                theme: "bubble",
                position: "bottom-right",
                type: "success",
                duration: 5000
              });
        })

      if (!video) return

      this.$router.push('/studio/videos')
      // console.log('submittied')
    },
    async getCategories() {
      this.categoryLoading = true
      const categories = await CategoryService.getAll()
        .catch((err) => {
          console.log(err)
        })
        .finally(() => (this.categoryLoading = false))

      this.categoriesTitles = categories.data.data.map((category) => {
        return category.title
      })
      this.categories = categories.data.data
    },

    toggleShow() {
			this.show = !this.show
    },

    cropSuccess(imgDataUrl, field) {
      console.log('-------- crop success --------')
      console.log(field)
      console.log(imgDataUrl)

      this.imgDataUrl = imgDataUrl
    }
  },
  components: {
    myUpload
  },
  mounted() {
    this.getVideo()
    this.getCategories()
  }
}
</script>

<style lang="scss">
.card {
  background: #f9f9f9 !important;
}
</style>
