<template>
  <div id="video" class="pa-4 pl-0">
<v-row class="fill-height history_common_section">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="8"
          lg="8"
          class="pt-3 pl-4"
        > 
        <v-row>
          <v-col md="1" cols="2"
          sm="2"
         
          order-sm="2"
          order-md="1"
          order-lg="1"
          lg="1">
          <!-- <v-avatar>
      <img v-if="currentUser.photoUrl" v-bind:src="`${getUrl}/uploads/avatars/${currentUser.photoUrl}`" :alt="`${currentUser.name}`" >
       <img v-else v-bind:src="`${getUrl}/no_profile.png`" :alt="`${currentUser.name}`" >
    </v-avatar> -->
    <!-- {{currentUser.photoUrl}} -->
             <v-avatar v-if="currentUser.photoUrl !== 'no-photo.jpg' && currentUser.photoUrl !== ''">
              <img
                v-bind:src="`${currentUser.photoUrl}`"
                :alt="`${currentUser.name} avatar`"
              />
            </v-avatar>
            <div class="avatarstyle" v-else>
              <span class="headline noimagestyle">
                {{ currentUser.channelName.split('')[0].toUpperCase() }}
              </span>
            </div>
          </v-col>
           <v-col md="11" cols="10"
          sm="10"
        
          order-sm="2"
          order-md="1"
          order-lg="1"
          lg="11">
          <h2>{{currentUser.channelName}}</h2>
          <p>{{subscribers.length}} followers</p>
          </v-col>
        </v-row>
        </v-col>
           <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="4"
          lg="4"
          class="pt-3 pl-4"
        >
     <div class="right_buttons_section">
       <v-btn   @click="dialog = true" depressed>
      ADD VIDEO
    </v-btn>
    <v-btn
      depressed
      color="primary"
    @click.stop="getVideos()">
      REFRESH
    </v-btn>
     </div>
         </v-col>
        </v-row>

    <!-- <v-container fluid> -->
    <h2 class="pt-2 my_video_titles">My videos</h2>

    <!-- <v-row> -->
    <!-- <v-tabs v-model="tab" id="tab" class="mt-5 common_tab_centers">
      <v-tab>
        Uploads
      </v-tab>
      <v-tab>
        Live
      </v-tab>
    </v-tabs> -->

    <!-- <v-tabs-items v-model="tab">
      <v-tab-item> -->
        <template>
          <v-card v-if="haveVideos === false" class="no_data_added">No data available</v-card>
          <v-card v-else flat>
            <v-card-title>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Filter"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
              no-data-text="No videos available, please upload video."
              :headers="headers"
              :items="videos"
              :search="search"
              :loading="loading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:item.feelings="{ item }">
                <span class="mr-3"
                  ><v-icon small class="pr-1">mdi-thumb-up</v-icon
                  >{{ item.likes }}</span
                >
                <span
                  ><v-icon small class="pr-1">mdi-thumb-down</v-icon
                  >{{ item.dislikes }}</span
                >
              </template>
              <template v-slot:top>
                <v-dialog v-model="dialogDelete" persistent max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline"
                        >Permanently delete this video?</span
                      >
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-card class="card" tile flat>
                          <v-row no-gutters>
                            <v-col cols="3" sm="2" md="5" lg="5">
                              <v-img
                                class="align-center"
                                :src="`${itemToDelete.thumbnailUrl}`"
                              >
                              </v-img>
                            </v-col>
                            <v-col>
                              <div class="ml-2">
                                <v-card-title
                                  class="pl-2  video_title_font_size"
                                  style="line-height: 1"
                                >
                                  {{ itemToDelete.title }}
                                </v-card-title>

                                <v-card-subtitle
                                  class="pl-2 pt-2 pb-0"
                                  style="line-height: 1"
                                >
                                  Published
                                  {{ dateFormatter(itemToDelete.createdAt) }}
                                  <br />
                                  {{ itemToDelete.views }} views
                                </v-card-subtitle>
                              </div>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                      style="background:#fe8f01; color:black !important"
                        color="blue darken-1"
                        text
                        @click="dialogDelete = !dialogDelete"
                        >Cancel</v-btn
                      >

                      <v-btn
                        style="background:#fe8f01; color:black !important"
                        :loading="deleteBtnLoading"
                        color="blue darken-1"
                        text
                        @click="deleteItem"
                        >Delete Forever</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn icon href text class="mr-2">
                  <v-icon @click="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  href
                  text
                  class="mr-2"
                  router
                  :to="`/watch/${item._id}`"
                >
                  <v-icon>
                    mdi-youtube
                  </v-icon>
                </v-btn>
                <!-- {{item}} -->
                <v-btn icon text @click.stop="deleteBtn(item)">
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
        </template>
      <!-- </v-tab-item>
      <v-tab-item>
        live
      </v-tab-item>
    </v-tabs-items> -->

    
    <!-- </v-row> -->
    <!-- </v-container> -->
    <v-snackbar v-model="snackbar">
      Video deleted successfully
      <v-btn color="white" text @click="snackbar = false" icon>
        <v-icon>mdi-close-circle</v-icon>
      </v-btn>
    </v-snackbar>
    <upload-video-modal
      :open-dialog="dialog"
      v-on:closeDialog="dialog = false"
    />
  </div>
</template>

<script>
import SubscriptionService from '@/services/SubscriptionService'

import UploadVideoModal from '@/components/UploadVideoModal'

import VideoService from "@/services/VideoService";
import moment from "moment";
import { mapGetters } from 'vuex'

export default {
  metaInfo() {
         return { 
            title: "exSports",
            meta: [
                { name: 'description', content:  "exSports - Videos"},
                { property: 'name:title', content: this.videometa[0].meta_title},
                { property: 'name:description', content: this.videometa[0].meta_description},
                { property: 'name:keyword', content: this.videometa[0].meta_keyword},
                { property: 'og:site_name', content: "exSports"},
                { property: 'og:type', content: 'exSports'},
                { name: 'robots', content: 'index,follow'} 
            ]
        }
    },
  data: () => ({
    videometa: '',
    loading: false,
    deleteBtnLoading: false,
    snackbar: false,
        dialog: false,

    dialogDelete: false,
    tab: null,
    search: "",
      subscribers: [],

    url: process.env.VUE_APP_API_URL,


    headers: [
      {
        text: "Video",
        align: "start",
        value: "title",
      },
      { text: "Visibility", value: "status" },
      { text: "Views", value: "views" },
      { text: "Comments", value: "comments" },
      { text: "Likes (vs. dislikes)", value: "feelings" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    videos: [],
    itemToDelete: {},
    haveVideos: true
  }),
   components: {
    UploadVideoModal
    
  },
    computed: {
    ...mapGetters(['currentUser', 'getUrl', 'isAuthenticated'])
  },
  methods: {
    async getVideos() {
      
      this.loading = true;

      const videos = await VideoService.getAll("private", { limit: 0 })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loading = false));
      // console.log("videosvideos", videos.data.data)
      this.videometa = videos.data.data;
      // console.log("videometa", this.videometa[0].meta_title)
      if (!videos) return;
      // console.log(videos)
      this.videos = videos.data.data;
      if(this.videos.length > 0){
        this.haveVideos = true
      }else{
        this.haveVideos = false   
      }
    },
     async getSubscribers() {
      this.loading = true
      const subscribers = await SubscriptionService.getSubscribers(0)
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false
        })

      if (!subscribers) return
      this.subscribers = subscribers.data.data

       console.log(subscribers)
    },
    editItem(item) {
      this.$router.push({ name: `Detail`, params: { id: item.id } });
    },
    deleteBtn(item) {
      this.dialogDelete = true;
      this.itemToDelete = item;
    },
    async deleteItem() {
      this.deleteBtnLoading = true;
      await VideoService.deleteById(this.itemToDelete._id)
        .catch((err) => console.log(err))
        .finally(() => {
          this.videos = this.videos.filter(
            (video) => this.itemToDelete.id !== video.id
          );
          this.deleteBtnLoading = false;
          this.dialogDelete = false;
          this.itemToDelete = {};
          this.snackbar = true;
        });
    },
    dateFormatter(date) {
      return moment(date).fromNow();
    },
  },
  mounted() {
    this.getVideos();
        this.getSubscribers();


  },
  beforeRouteUpdate(to, from, next) {
    this.getVideos();
    next();
  },
};
</script>

<style lang="scss">

.avatarstyle{
    background: #f68f32;
    align-items: center; 
    border-radius: 50%; 
    height: 48px; 
    width: 50px;   
}
.noimagestyle{
  display: inline-block; 
  width: 100%;
  text-align: center;
  line-height: 48px !important; 
  color: #ffffff;
}
// .card,
// #tab .v-tabs-bar {
//   background: #f9f9f9 !important;
// }
</style>
